import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1056.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
					<path d="M4971 8444 c-287 -31 -578 -107 -895 -233 -86 -35 -342 -161 -421
-208 -426 -253 -760 -579 -949 -924 l-36 -66 72 -6 c277 -24 550 -29 1233 -21
1103 14 2036 12 2180 -4 428 -48 711 -176 1000 -454 166 -159 277 -325 363
-544 92 -231 127 -433 119 -684 -8 -234 -37 -357 -132 -555 -254 -527 -875
-860 -1789 -960 -77 -9 -142 -17 -144 -19 -2 -2 -21 -66 -42 -143 -21 -76
-108 -385 -193 -688 -86 -302 -159 -563 -163 -578 l-7 -29 124 6 c294 16 460
39 699 98 574 141 1079 425 1490 837 277 278 477 572 633 931 308 708 312
1529 11 2299 -99 252 -275 575 -409 752 -463 608 -1106 1008 -1855 1154 -271
52 -624 68 -889 39z"/>
					<path d="M5630 6274 c-41 -2 -323 -5 -627 -6 -303 -2 -554 -6 -558 -9 -3 -4
-49 -168 -101 -365 -52 -198 -144 -543 -204 -769 -122 -461 -150 -569 -150
-584 0 -8 100 -11 330 -11 261 0 330 -3 330 -13 0 -21 -48 -289 -70 -392 -52
-238 -97 -302 -225 -319 -27 -4 -149 -2 -270 3 -180 8 -238 14 -318 35 -452
116 -592 446 -477 1124 25 140 82 388 151 647 57 215 139 574 139 611 l0 24
-640 0 -639 0 -6 -32 c-31 -184 -72 -599 -81 -823 -10 -244 21 -561 76 -777
91 -355 306 -791 539 -1093 122 -159 376 -415 541 -546 179 -141 402 -280 592
-366 133 -60 353 -137 372 -130 19 8 141 331 175 467 53 209 246 954 464 1800
161 620 163 627 171 663 l6 27 431 0 c405 0 430 -1 425 -17 -3 -10 -25 -92
-50 -183 -25 -91 -79 -284 -119 -430 -86 -309 -91 -285 58 -275 199 15 366 68
511 164 401 265 541 793 309 1166 -127 205 -357 344 -645 389 -101 16 -315 26
-440 20z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
